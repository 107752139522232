<template>
  <div>
    <Map :deviceItems="deviceData" @closefullmap="closeFullMap" :mapData="mapdata"></Map>
  </div>
</template>

<script>
import Map from '../../components/VMapDayawan.vue'; //地图
export default {

  data() {
    return {
      deviceData: [],//数据
      is_screenMap: false,//是否全屏
      mapdata: {   // 大亚湾大地图
        center: {
          lng: 114.54042,
          lat: 22.741633,
        },
        zoom: 14
      }
    }
  },
  components: {
    Map,

  },
  mounted() {
    this.dataRequest()//获取内涝数据
    this.screen()//全屏化
  },
  methods: {
    //全屏
    screen() {
      //隐藏左侧列表和顶部菜单标题
      this.is_screenMap = true
      this.$store.commit("settingStore/SET_FULLSCREEN", true);
      //浏览器全屏显示
      let element = document.documentElement;
      if (this.is_screenMap) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      } else {

      }

      // this.is_screenMap = !this.is_screenMap;
    },
    //获取全部设备
    dataRequest() {
      let json = {
        "cmd": "devices"
      }
      this.ws.send(json);
      this.ws.addCallback('devicesAck', (res) => {
        //过滤出内涝数据
        // console.log("设备数据：", res);
        this.deviceData = [] //有新数据时复位内涝数组
        res.devices.forEach(item => {
          if (item.model == 'dmWater') {
            this.deviceData.push({ 'uid': item.uid, 'title': item.title, 'model': item.model })
          }
        })
        this.setHotDevices() //设置内涝设备为热点设备
      })
      this.getData()
    },

    //接收实时值
    getData(obj) {
      this.ws.addCallback('event', (res) => {
        // console.log("大屏接收res数据", res);

        if (res.type != "sensor") {
          return;
        } else {
          let uidData = this.getDevice(res.uid);
          let data;
          if (uidData != null) {
            data = this.parseProfile.parseData2(uidData.model, res.data ? res.data : "");
            // console.log("大屏接收res数据", data[0], data);

            this.deviceData.forEach((item, index) => {
              if (item.uid == res.uid && data.length > 0) {
                let f_key = data[0].findIndex(val => { return val.key === "f" })
                let s_key = data[0].findIndex(val => { return val.key === "s" })
                this.deviceData[index].f = data[0] ? data[0][f_key].value : ''
                this.deviceData[index].s = data[0] ? data[0][s_key].value : ''
                this.deviceData[index].time = this.getTime()
              }
            })
          }
        }
      })
    },
    // 设置热点设备
    setHotDevices() {
      let uids = "";
      this.deviceData.forEach((item) => {
        uids += item.uid + ",";
      })
      let json = {
        "cmd": "setHotDevices",
        "uids": uids
      }
      this.ws.send(json);
    },
    getDevice(uid) {
      let obj = this.common.cFindObj(this.deviceData, "uid", uid);
      return obj;
    },
    getTime() {
      var myDate = new Date();
      return myDate.getMonth() + 1 + '.' + myDate.getDate() + ' ' + myDate.getHours() + ':' + myDate.getMinutes()
    },
    closeFullMap() {
      //隐藏左侧列表和顶部菜单标题
      this.is_screenMap = false
      this.$store.commit("settingStore/SET_FULLSCREEN", false);
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      this.$router.push({ path: '/visual' })
    }
  },
  destroyed() {
    if (this.is_screenMap) {
      this.closeFullMap()
    }
  }
}
</script>

<style>
</style>
